import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ModalsHeaderNews from './ModalsHeaderNews';

export default function ModalsTermsOfUse(props) {
    return (

        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="modals"
        >
            <ModalsHeaderNews />

            <DialogTitle id="alert-dialog-title">{"Condições de utilização"}</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Os dados de questionário recolhidos nesta aplicação são anónimos e não serão divulgados. Todos os dados serão analisados e tratados para fins estatísticos.. Ao participar, declaras que autorizas o tratamento dos dados recolhidos para os fins previstos e que tomaste conhecimento da <a target="blank" href="http://www.adnorte.pt/downloads/file779_pt.pdf">Política de Privacidade</a> da Águas do Norte S.A., onde consta a referência aos teus direitos enquanto Titular de Dados.
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onRefuseClick} color="primary">
                    Não autorizo
                </Button>
                <Button onClick={props.onAcceptClick} color="primary" autoFocus>
                    Autorizo
        </Button>
            </DialogActions>
        </Dialog>
    );
}