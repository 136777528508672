import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import CompanyLogo from '../../components/_Global/CompanyLogo';

const companyName = ['Gonçalves e Chaves Lda.', 'SONAE S.A.', 'WhyMob S.A.'];
const useStyles = makeStyles({
  centerElement: {
    textAlign: 'center',
  },
  logo: {
    width: '150px',
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    cursor: 'pointer',
  },
});

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Alterar organização
      <Typography variant="subtitle2">Seleccione em baixo uma organização que esteja associado para mudar.</Typography>
      </DialogTitle>
      <List>
        {companyName.map(name => (
          <ListItem button onClick={handleListItemClick.bind(this, name)} key={name}>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancelar
          </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function CompanyLogoContainer() {
  
  const classes = useStyles();
  return (
    <div>
      <div className={classes.centerElement}>
        <CompanyLogo></CompanyLogo>
        {/*       
            <button className={classes.button} onClick={handleClickOpen.bind(this)}>
                <img src={Logo} alt={'Alt text here'} className={classes.logo} />
            </button>
            <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
            */}
      </div>
    </div>
  );
}