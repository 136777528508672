import React, { useEffect, useState } from 'react';
import MoodHeaderDays from '../../components/Mood/MoodHeaderDays';
import * as diaryService from '../../api/diaryService';
import * as moodService from '../../api/moodService';
import { connect } from 'react-redux';
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import { useHistory } from 'react-router-dom';
import { daysOfTheWeekNamesEN } from '../../utils/utils'


function MoodHeaderDaysContainer(props) {

    const history = useHistory();
    const [currentDay, setCurrentDay] = useState(0);
    const [preselectedDay, setPreselectedDay] = useState("");
    const [currentWeekFullDays, setCurrentWeekFullDays] = React.useState([]);

    useEffect(() => {
        // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
        async function fetchData() {
            try {
                const getCurrentDayDate = await diaryService.getCurrentDay();
                let parsedDate = new Date(getCurrentDayDate);

                setCurrentDay(parsedDate);
                setPreselectedDayName(parsedDate);

                const getCurrentWeekDays = await diaryService.getCurrentWeekFullDays(setCurrentWeekFullDays)

                setCurrentWeekFullDays(getCurrentWeekDays);

            } catch (e) {
                history.push("/");
                console.error("ERROR ORCCURRED - REDIRECTING TO HOMEPAGE: " + e);
            }
        };
        fetchData();
    }, []);

    function setPreselectedDayName(day) {
        let dayName = day.toLocaleDateString('en-EN', { weekday: 'long' });
        setPreselectedDay(dayName);
    }

    function mapAndUpdateMoodHeader(moods) {
        for (let dayIndex = 0; dayIndex < currentWeekFullDays.length; dayIndex++) {
            const day = new Date(currentWeekFullDays[dayIndex]).toISOString();

            const moodDayIndex = moods.map(m => new Date(m.diary.dateValue).toISOString()).indexOf(day);


            const mood = moodDayIndex >= 0 ? moods[moodDayIndex] : null;
            if (mood != null) {

                if (day === new Date(mood.diary.dateValue).toISOString()) {

                    const commentData = mood.comment != null
                        ? {
                            textValue: mood.comment.text,
                            isCommentAnonymous: mood.comment.isAnonymous
                        }
                        : null;


                    props.updateMood(
                        daysOfTheWeekNamesEN[dayIndex],
                        0, // selected mood rating
                        mood.moodType.value,
                        mood.id,
                        day,
                        commentData,
                        mood.workPlaceValue
                    );
                }
            }
            else {
                // If its the current day and the mood is null
                // OR if its a previous day and the mood is null
                if (dayIndex <= daysOfTheWeekNamesEN.indexOf(preselectedDay)) {


                    props.updateMood(daysOfTheWeekNamesEN[dayIndex], 0, 0, 0, day);
                }
            }

        }
    }

    function renderMoodHeaderDays() {

        if (currentWeekFullDays != null
            && currentWeekFullDays.length) {

            moodService.getMoodsForCurrentWeek(mapAndUpdateMoodHeader)
                .then((moods) => {
                    mapAndUpdateMoodHeader(moods);
                })

            return (
                <MoodHeaderDays
                    translateFunc={props.translateFunc}
                    i18n={props.i18n}
                    currentDay={currentDay}
                    preselectedDay={preselectedDay} />
            );
        }
    }

    return (
        <div>
            {renderMoodHeaderDays()}
        </div>
    );
}


const mapDispatchToProps = dispatch => {

    return {
        updateMood: (currentDayName, selectedMoodRating, moodRatingFromDb, moodId, diaryDate, commentData, workPlace) => dispatch(
            moodDetailActions.updateMood(
                currentDayName,
                selectedMoodRating,
                moodRatingFromDb,
                moodId,
                diaryDate,
                commentData,
                workPlace)
        )
    };
};

export default connect(null, mapDispatchToProps)(MoodHeaderDaysContainer);