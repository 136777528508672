import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { materialOverride } from "../../css/globalStyles.js";
import { connect } from 'react-redux';
import MoodInputContainer from '../../containers/Mood/MoodInputContainer';
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import { daysOfTheWeekNamesEN } from '../../utils/utils'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        //backgroundColor: 'rgba(255, 255, 255, 1)',
        //width: 500,
    },
    title: {
        color: 'white',
    },

}));

function MoodHeaderDays(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [value, setValue] = React.useState(0);
    const [canRenderTabs, setCanRenderTabs] = React.useState(false);

    const daysOfTheWeekTabInitialPT = [
        props.translateFunc("MOOD_DAY_SATURDAY_INITIAL"),
        props.translateFunc("MOOD_DAY_SUNDAY_INITIAL"),
        props.translateFunc("MOOD_DAY_MONDAY_INITIAL"),
        props.translateFunc("MOOD_DAY_TUESDAY_INITIAL"),
        props.translateFunc("MOOD_DAY_WEDNESDAY_INITIAL"),
        props.translateFunc("MOOD_DAY_THURSDAY_INITIAL"),
        props.translateFunc("MOOD_DAY_FRIDAY_INITIAL")];

    useEffect(() => {

        setPreselectedDayOfTheWeekIndex();
        // setTimeout(() => {
        //     setPreselectedDayOfTheWeekIndex();
        // }, 100) // HACK JS: remove this becaus timeouts are bad
    }, [])

    const handleChange = (event, index) => {
        props.onDayOfTheWeekSelected(index);
        setValue(index);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    function setPreselectedDayOfTheWeekIndex() {

        let index = daysOfTheWeekNamesEN.indexOf(props.preselectedDay);

        if (index >= 0) {
            setValue(index);
            props.onDayOfTheWeekSelected(index);
            setCanRenderTabs(true);
        }

    }

    function renderMoodInputContainers() {
        if (props.currentWeekMoods === null) {
            return (<div></div>);
        }

        let moodInputContainers = [];

        for (let index = 0; index <= 6; index++) {

            const mood = props.currentWeekMoods != null
                ? props.currentWeekMoods[index].mood
                : null;


            moodInputContainers.push(
                // We only want to render a single component each time,
                // the one that is currently selected is the one whose value equals the index
                <MoodInputContainer
                    translateFunc={props.translateFunc}
                    i18n={props.i18n}
                    key={daysOfTheWeekNamesEN[index]}
                    dayOfTheWeekIndex={index}
                    currentDay={props.currentDay}
                    isSelected={value === index}
                    associatedMood={mood || null} />
            );
        }
        return moodInputContainers;
    }

    function renderDayOfWeekTabs() {
        let tabsElements = [];
        let currentDayDate = new Date(props.currentDay);
        let currentDayName = currentDayDate.toLocaleDateString('en-EN', { weekday: 'long' });

        for (let index = 0; index <= 6; index++) {

            let isDisabled = false;
            let isFilled = false;

            // Disabled explanation
            // The days after the current one are disabled
            // So we check for the index of the current day, and if the iteration's index is higher
            // we disable it.
            if (index > daysOfTheWeekNamesEN.indexOf(currentDayName)) {
                isDisabled = true;
            }

            // We want to highlight days that were already filled
            if (!isDisabled) {
                const mood = props.currentWeekMoods != null
                    ? props.currentWeekMoods[index].mood
                    : null;

                if (mood != null && mood.id !== 0) {
                    isFilled = true;
                }

            }

            tabsElements.push(
                <Tab
                    key={index}
                    disabled={isDisabled}
                    label={daysOfTheWeekTabInitialPT[index]}
                    {...a11yProps(index)}
                    className={classes.title, isFilled ? "filled" : ""} />
            );

        }
        return tabsElements;
    }

    return (
        <div className={classes.root}>

            <AppBar position="static" style={materialOverride.whiteTransparencyColor}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >

                    {canRenderTabs ? renderDayOfWeekTabs() : null}

                </Tabs>
            </AppBar>

            <SwipeableViews
                className="mooodSwipeable"
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}>

                {renderMoodInputContainers()}

            </SwipeableViews>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedDayOfTheWeekIndex: state.moodDetailReducer.selectedDayOfTheWeekIndex,
        currentWeekMoods: state.moodDetailReducer.currentWeekMoods
    };
}

const mapDispatchToProps = dispatch => {

    return {
        onDayOfTheWeekSelected: (index) => dispatch(
            moodDetailActions.onDayOfTheWeekSelected(index))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoodHeaderDays);