import React from 'react';

export default function ModalsHeaderNews() {

  return (
    <div>
      <div className="ModalsHeader">
        <div>
          <img alt={""} src={process.env.REACT_APP_COMPANY_LOGO_URL} className="logo"/>
        </div>
      </div>
      <div className="ModalsHeaderShadow"></div>
    </div>
  );
}
