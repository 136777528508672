import React from 'react';

export default function CompanyLogo() {

  return (
    <div className="CompanyLogo">      

      <img src={process.env.REACT_APP_COMPANY_LOGO_URL}
        alt={'Organização'} />
    </div>
  );
}